<template>
  <div>
    <v-card>
      <v-container class="pt-1">
        <v-col class="pt-0">
          <v-row>
            <v-col class="pb-0">
              <h1 class="pt-2">Capacitaciones</h1>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              small
              class="mr-3 mt-5"
              elevation="5"
              fab
              @click="clickAlert({})"
            >
              <v-icon height="26" style="font-size: 16px !important"
                >fas fa-plus</v-icon
              >
            </v-btn>
          </v-row>
        </v-col>
        <v-row justify="center" class="mt-0" v-show="false">
          <v-col lg="2" class="">
            <s-date label="Fecha" v-model="filter.TvDate" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div class="mt-3">
      <v-alert
        v-for="a in itemsIntruction"
        v-model="alert"
        @click="clickAlert(a)"
        @touchend="clickAlert(a)"
        :color="a.VstID ? 'success' : 'primary'"
        class="pointer"
        border="left"
        elevation="10"
        centered
        colored-border
        icon="far fa-calendar-alt"
      >
        <strong
          >{{ a.TypeVisitName }} {{ a.FagName }} - {{ a.CtvName }} -
          {{ a.VrtName }} - {{ a.FltName }}</strong
        >
        <p class="pb-0 pt-0 mt-0 mb-0">Dirección: {{ a.FagAddress }}</p>
        <p class="pb-0 pt-0 mt-0 mb-0">Ref. Parcela: {{ a.PlotReference }}</p>
        <p class="pb-0 pt-0 mt-0 mb-0">
          Latitud: {{ a.FagLatitude }} Longitud: {{ a.FagLatitude }}
        </p>
      </v-alert>
    </div>

    <v-dialog
      full-screen
      fullscreen
      v-model="dialogIntruction"
      permanent
      v-if="dialogIntruction"
      persistent
      width="100%"
    >
      <intruction-edit
        @close="
          dialogIntruction = false;
          initializeData();
        "
        :intructionSchedule="intructionSchedule"
      ></intruction-edit>
    </v-dialog>
  </div>
</template>
<script>
import IntructionEdit from "@/views/HumanResource/HumanManagement/IntructionDetail/InstructionEdit";
import _sVisit from "@/services/Technicalassistance/TasTechnicalVisitService.js";
export default {
  components: {
    IntructionEdit,
  },

  data() {
    return {
      intructionSchedule: null,
      alert: true,
      itemsIntruction: [],
      dialogIntruction: false,
      filter: {
        TvDate: null,
        TypeVisit: 0,
      },
    };
  },

  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      let obj = {
        NtpID: this.$fun.getUserInfo().NtpID,
        TvDate: this.filter.TvDate,
        TypeVisit: this.filter.TypeVisit,
      };
      _sVisit.BySupervisorByDate(obj, this.$fun.getUserID()).then((r) => {
        this.itemsIntruction = r.data;
      });
    },
    clickAlert(a) {
      if (a.VstID == null) {
        this.dialogIntruction = true;
        this.intructionSchedule = a;
      } else {
        this.dialogIntruction = true;
        this.intructionSchedule = a;
      }
    },
  },
};
</script>
<style scope>
.pointer {
  cursor: pointer;
}
.pointer:hover {
  border-style: dashed;
}
</style>
