<template>
  <div>
    <v-row>
      <v-col class="pb-0">
        <h3>Estado de Capacitacion(Aplicaciones Realizadas)</h3>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        x-small
        class="mr-3"
        elevation="5"
        fab
        @click="add()"
      >
        <v-icon height="26" style="font-size: 14px !important"
          >fas fa-plus</v-icon
        >
      </v-btn>
    </v-row>
    <v-row class="pb-3">
      <v-divider> </v-divider>
    </v-row>
    <v-dialog permanent width="700" v-model="dialogAdd" v-if="dialogAdd">
      <v-card>
        <v-container>
          <v-col>
            <v-row>
              <h2>Nueva Aplicación</h2>
            </v-row>
          </v-col>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <v-col lg="12">
              <v-row justify="center">
                <v-col lg="5" class="pt-0 pb-0" cols="12">
                  <s-select-listsupplies
                    add
                    label="Producto"
                    ref="objProductApplication"
                    return-object
                    :PlID="0"
                    :TypeCultive="visitSchedule.TypeCultive"
                    v-model="objProductApplication"
                    @input="inputProduct($event)"
                    v-if="inputObject"
                  />
                  <!-- MODIFICAR COMPONENTE -->
                </v-col>
                <v-col lg="2" class="pt-0 pb-0" cols="12">
                  <s-text
                    label="Dosis"
                    ref="ppaDose"
                    decimal
                    v-model="ppaDose"
                  />
                </v-col>

                <v-col lg="1" class="pt-0" cols="12">
                  <v-btn
                    fab
                    class="mt-2"
                    color="success"
                    rounded
                    @click="addProduct()"
                    small
                    block
                  >
                    <v-icon height="20" style="font-size: 14px !important"
                      >fas fa-plus</v-icon
                    ></v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-data-table
                    :items="item.TasVisitProductApplications"
                    :headers="[
                      { text: '', value: 'Line' },
                      { text: 'Producto', value: 'LsName' },
                      { text: 'Dosis', value: 'PpaDose' },
                      {
                        text: 'Acciones',
                        value: 'Actions',
                        align: 'center',
                        width: '90',
                        sortable: false,
                      },
                    ]"
                    dense
                    :items-per-page="5"
                  >
                    <template v-slot:item.Actions="{ item }">
                      <v-btn
                        @click="clickDeleteApplication(item)"
                        x-small
                        color="error"
                        elevation="0"
                        block
                      >
                        <v-icon style="font-size: 16px !important"
                          >fas fa-times</v-icon
                        >
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-spacer></v-spacer>
                <v-col lg="6" class="pt-0">
                  <s-text
                    number
                    v-model="item.PapLiterWater"
                    label="Litros de Agua"
                  />
                </v-col>
                <v-col lg="6" class="pt-0">
                  <s-date label="Fecha" v-model="item.PapDate"> </s-date>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  ><v-btn
                    block
                    rounded
                    color="warning"
                    @click="dialogAdd = false"
                    >Cancelar</v-btn
                  ></v-col
                >
                <v-col
                  ><v-btn
                    block
                    rounded
                    color="primary"
                    @click="
                      save();
                      dialogAdd = false;
                    "
                    >Agregar</v-btn
                  ></v-col
                ></v-row
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import sSelectListsupplies from "@/components/TecnicalAssistence/ListSupplies/SSelectListsupplies";

export default {
  components: { sSelectListsupplies },
  props: {
    visitSchedule: { type: Object },
    value: { type: Array },
  },
  watch: {},
  data: () => ({
    dialogAdd: false,
    items: [],
    ppaDose: null,
    objProductApplication: null,
    objTypeUnit: null,
    item: {
      TasVisitProductApplications: [],
      PapLiterWater: null,
      PapDate: null,
      SecStatus: 1,
    },
  }),
  methods: {
    inputObject(item) {
      alert("sdsdsds");
    },

    inputProduct(item) {
      if (item)
        this.objTypeUnit = {
          DedValue: item.LsUnitID,
          DedDescription: item.DedDescription,
        };
    },
    orderApplication() {
      let i = 0;
      this.item.TasVisitProductApplications.map((e) => {
        i++;
        e.Line = i;
      });
    },
    clickDeleteApplication(item) {
      this.item.TasVisitProductApplications =
        this.item.TasVisitProductApplications.filter(
          (x) => x.Line != item.Line
        );
      this.orderApplication();
    },
    restart() {
      this.item = {
        SecStatus: 1,
        TasVisitProductApplications: [],
        PapLiterWater: null,
        PapDate: null,
      };
    },
    add() {
      this.dialogAdd = true;
    },
    save() {
      if (this.item.TasVisitProductApplications.length <= 0) {
        this.$fun.alert("Ingrese productos aplicados", "warning");
        return;
      }
      this.item.TasProductsApplicationName = "";
      this.item.TasVisitProductApplications.map((e) => {
        this.item.TasProductsApplicationName =
          this.item.TasProductsApplicationName +
          " " +
          e.PpaDose +
          " " +
          e.LsName +
          " + ";
      });

      this.item.TasProductsApplicationName =
        this.item.TasProductsApplicationName +
        "  / " +
        (this.item.PapLiterWater ? this.item.PapLiterWater : "0") +
        " Litros de Agua";

      this.item.Line = this.items.length + 1;
      this.item.SecStatus = 1;
      this.items.push(this.item);

      this.$emit("input", this.items);
      this.restart();
    },
    addProduct() {
      if (!this.objProductApplication) {
        this.$fun.alert("Seleccione Producto", "warning");
        this.$refs.objProductApplication.focus();
        return;
      }
      if (this.ppaDose) {
        if (parseInt(this.ppaDose) <= 0) {
          this.$refs.ppaDose.error("Dosis Incorrecta");
          return;
        }
      }
      if (!this.ppaDose) {
        this.$refs.ppaDose.error("Dosis Incorrecta");
        return;
      }
      this.item.TasVisitProductApplications.push({
        TypeUnit: this.objTypeUnit.DedValue,
        TypeUnitName: this.objTypeUnit.DedDescription,
        PpaDose: this.ppaDose,
        LsID: this.objProductApplication.LsID,
        LsName: this.objProductApplication.LsDescription,
        Line: this.item.TasVisitProductApplications.length + 1,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      });

      this.objProductApplication = null;
      this.ppaDose = null;
      this.$refs.objProductApplication.focus();
    },
  },
  created() {},
};
</script>
