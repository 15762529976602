<template>
  <div>
    <v-card>
      <v-toolbar class="elevation-0" dark color="primary" height="40">
        <h2>Capacitaciones</h2>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col>
        <v-row>
          <v-container>
            <v-row justify="center" class="mt-0 mb-6">
              <v-col lg="2" cols="12" class="pb-0 pt-1"
                ><s-date v-model="VstDate"></s-date
              ></v-col>

              <v-col
                class="pb-0 pt-1"
                lg="4"
                cols="12"
                v-if="intructionSchedule.TvID"
              >
                <s-text
                  readonly
                  label="Fundo"
                  :value="intructionSchedule.FagName"
                />
              </v-col>
              <v-col
                class="pb-0 pt-1"
                lg="2"
                cols="12"
                v-if="intructionSchedule.TvID"
              >
                <s-text
                  readonly
                  label="Cultivo"
                  :value="intructionSchedule.CtvName"
                />
              </v-col>
              <v-col
                class="pb-0 pt-1"
                lg="2"
                cols="12"
                v-if="intructionSchedule.TvID"
              >
                <s-text
                  readonly
                  label="Variedad"
                  :value="intructionSchedule.VrtName"
                />
              </v-col>
              <v-col
                class="pb-0 pt-1"
                lg="2"
                cols="12"
                v-if="intructionSchedule.TvID"
              >
                <s-text
                  readonly
                  label="Lote"
                  :value="intructionSchedule.FltName"
                />
              </v-col>
              <v-col
                class="pb-0 pt-1"
                lg="2"
                cols="6"
                v-if="intructionSchedule.TvID == null"
              >
                <s-select-definition
                  :def="1067"
                  v-model="intructionSchedule.TypeInstruction"
                  label="Tipo"
                />
              </v-col>

              <v-col
                class="pb-0 pt-1"
                lg="3"
                cols="6"
                v-if="intructionSchedule.TvID == null"
              >
                <s-select-definition
                  :def="1357"
                  v-model="intructionSchedule.TypeInstruction"
                  label="Capacitacion"
                />
              </v-col>
              <v-col
                class="pb-0 pt-1"
                lg="3"
                cols="6"
                v-if="intructionSchedule.TvID == null"
              >
                <s-select-definition
                  :def="1356"
                  v-model="intructionSchedule.TypeInstruction"
                  label="Condicion"
                />
              </v-col>
            <v-col
                class="pb-0 pt-1"
                lg="3"
                cols="6"
                v-if="intructionSchedule.TvID == null"
              >
                <s-select-definition
                  :def="1358"
                  v-model="intructionSchedule.TypeCultive"
                  label="Modalidad"
                />
              </v-col>
              <v-col
                class="pt-1 pb-0"
                lg="7"
                cols="12"
                v-if="intructionSchedule.TvID == null"
              >
                <s-text
                  label="Observación"
                  :value="lotSenasa ? lotSenasa.FltName : ''"
                />
              </v-col>
                
            </v-row>
            <instruction-subject-instructor
              :intructionSchedule="intructionSchedule"
              v-model="TasVisitPerformedApplications"
            />

            <v-row>
              <v-col>
                <v-data-table
                  :items="TasVisitPerformedApplications"
                  :headers="[
                    { text: '', value: 'Line' },
                    {
                      text: 'Aplicaciones Fitosanitarias y Fertilizantes Realizadas',
                      value: 'TasProductsApplicationName',
                    },
                    { text: 'Fecha', value: 'PapDate' },
                    {
                      text: 'Acciones',
                      value: 'Actions',
                      align: 'center',
                      width: '90',
                      sortable: false,
                    },
                  ]"
                  dense
                  :items-per-page="5"
                >
                  <template v-slot:item.Actions="{ item }">
                    <v-btn
                      @click="clickDeleteRecomendations(item)"
                      x-small
                      color="error"
                      elevation="0"
                      block
                    >
                      <v-icon style="font-size: 16px !important"
                        >fas fa-times</v-icon
                      >
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col lg="2" cols="12" class="pr-0">
                <h3 class="mb-2">Etapa Fenológica (%)</h3>
                <v-col
                  cols="12"
                  class="pb-0 pt-1 pl-0"
                  v-for="i in itemsTypePhenological"
                >
                  <s-text
                    :label="
                      i.DedDescription
                        ? i.DedDescription
                        : i.TypeStagePheologicalName + ' (%)'
                    "
                    v-model="i.StpPercentage"
                    decimal
                  />
                </v-col>
              </v-col>
              <!--EVALUACION FOTOSANITARIA-->
              <v-col lg="5" cols="12">
                <v-row>
                  <v-col>
                    <h3>Evaluación Fitosanitaria</h3>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="addPhenological()"
                    x-small
                    class="mr-3 mt-1"
                    elevation="5"
                    fab
                  >
                    <v-icon height="26" style="font-size: 14px !important"
                      >fas fa-plus</v-icon
                    >
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col lg="6" class="pt-0 pb-0" cols="12">
                    <s-select-plagues
                      :typeCultive="
                        intructionSchedule.TypeCultive
                          ? intructionSchedule.TypeCultive
                          : lotSenasa
                          ? lotSenasa.TypeCultive
                          : 0
                      "
                      return-object
                      clearable
                      ref="objPlague"
                      v-model="objPlague"
                      label="Enfermadad/Plaga/Maleza"
                    />
                  </v-col>
                  <v-col lg="3" class="pt-0 pb-0" cols="12">
                    <s-select-definition
                      :def="1208"
                      clearable
                      return-object
                      v-model="objOrganPlant"
                      label="Organo"
                    />
                  </v-col>
                  <v-col lg="3" class="pt-0 right" cols="12">
                    <s-text
                      placeholder="000"
                      label="Pocentaje"
                      ref="PercentagePlague"
                      v-mask="'###'"
                      v-model="PercentagePlague"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <v-data-table
                      :headers="[
                        { text: '', value: 'Line', sortable: false },
                        { text: 'Plaga', value: 'PlName', sortable: false },
                        {
                          text: 'Organo',
                          value: 'TypeOrganName',
                          sortable: false,
                        },
                        {
                          text: '%',
                          value: 'PhePercentage',
                          width: 50,
                          sortable: false,
                          align: 'center',
                        },
                        {
                          text: 'Acciones',
                          value: 'Actions',
                          align: 'center',
                          width: '90',
                          sortable: false,
                        },
                      ]"
                      dense
                      :items="itemsPhenological"
                      :items-per-page="5"
                    >
                      <template v-slot:item.Actions="{ item }">
                        <v-btn
                          @click="clickDeletePhenological(item)"
                          x-small
                          color="error"
                          elevation="0"
                          block
                        >
                          <v-icon style="font-size: 16px !important"
                            >fas fa-times</v-icon
                          >
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
              <!--Actividades Realizadas-->
              <v-col lg="5" cols="12">
                <v-row>
                  <v-col>
                    <h3>Actividades Realizadas</h3>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    x-small
                    class="mr-3 mt-1"
                    elevation="5"
                    fab
                    @click="addActivities()"
                  >
                    <v-icon height="26" style="font-size: 16px !important"
                      >fas fa-plus</v-icon
                    >
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col lg="4" class="pt-0 pb-0" cols="12">
                    <s-select-definition
                      :def="1204"
                      v-model="objTypeActivity"
                      @updateData="updateDataActivities($event)"
                      label="Actividad"
                      return-object
                    />
                  </v-col>
                  <v-col lg="4" class="pt-0 pb-0" cols="12">
                    <s-date
                      label="Fecha Inicio"
                      v-model="ActBeginDate"
                    ></s-date>
                  </v-col>
                  <v-col lg="4" class="pt-0 pb-0" cols="12">
                    <s-date label="Fecha Fin" v-model="ActEndDate"></s-date>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      :items="itemsActivities"
                      :headers="[
                        { text: '', value: 'Line' },
                        { text: 'Actividad', value: 'TypeActivityName' },
                        { text: 'Fecha Inicio', value: 'PacBeginDate' },
                        { text: 'Fecha Fin', value: 'PacEndDate' },
                        {
                          text: 'Acciones',
                          value: 'Actions',
                          align: 'center',
                          width: '90',
                          sortable: false,
                        },
                      ]"
                      dense
                      :items-per-page="5"
                    >
                      <template v-slot:item.Actions="{ item }">
                        <v-btn
                          @click="clickDeleteActivities(item)"
                          x-small
                          color="error"
                          elevation="0"
                          block
                        >
                          <v-icon style="font-size: 16px !important"
                            >fas fa-times</v-icon
                          >
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <h3>Evaluación de Instalaciones</h3>
              </v-col>
            </v-row>
            <v-row><v-divider></v-divider></v-row>
            <v-row>
              <v-col
                v-for="i in itemsTypeInstallations"
                lg="2"
                class="pb-0 pt-0"
              >
                <s-switch
                  :label="
                    i.DedDescription ? i.DedDescription : i.TypeInstallationName
                  "
                  v-model="i.IneStatus"
                  color="primary"
                ></s-switch>
              </v-col>
              <v-col lg="2" cols="12" class="pb-0 pt-0">
                <s-switch
                  inset
                  label="LIBRE RESIDUOS"
                  v-model="VstNoWaste"
                  color="success"
                ></s-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <h3>Recomendaciones</h3>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                x-small
                class="mr-3 mt-1"
                elevation="5"
                @click="addRecomendation()"
                fab
              >
                <v-icon height="26" style="font-size: 16px !important"
                  >fas fa-plus</v-icon
                >
              </v-btn>
            </v-row>
            <v-row><v-divider></v-divider></v-row>
            <v-row justify="center">
              <v-col lg="2" class="pb-0" cols="12">
                <s-select-definition
                  :def="1214"
                  v-model="objTypeApplication"
                  label="Tipo de Aplicación"
                  return-object
                />
              </v-col>
              <v-col lg="3" class="pb-0" cols="12">
                <s-select-plagues
                  return-object
                  clearable
                  :typeCultive="
                    intructionSchedule.TypeCultive
                      ? intructionSchedule.TypeCultive
                      : lotSenasa
                      ? lotSenasa.TypeCultive
                      : 0
                  "
                  ref="objPlagueRecomendation"
                  v-model="objPlagueRecomendation"
                  label="Enfermedad/Plaga/Maleza"
                />
              </v-col>
              <v-col lg="3" class="pb-0" cols="12">
                <s-select-listsupplies
                  :full="objPlagueRecomendation == null ? false : true"
                  label="Producto"
                  return-object
                  :PlID="
                    objPlagueRecomendation == null
                      ? 0
                      : objPlagueRecomendation.PlID
                  "
                  v-model="objProductRecomedation"
                  @input="inputProductRecomendation($event)"
                />
              </v-col>
              <v-col lg="2" class="pb-0" cols="12">
                <s-text
                  decimal
                  label="Dosis"
                  ref="DoceRecomendation"
                  v-model="DoceRecomendation"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :items="itemsRecomendation"
                  :headers="[
                    { text: '', value: 'Line', width: '70' },
                    {
                      text: 'Tipo Aplicación',
                      value: 'TypeApplicationName',
                      width: '120',
                    },
                    {
                      text: 'Enfermedad/Plaga/Maleza',
                      value: 'PlName',
                      width: '200',
                    },
                    { text: 'Producto', value: 'LsName' },
                    { text: 'Dosis', value: 'RcmDose', width: '120' },
                    {
                      text: 'Acciones',
                      value: 'Actions',
                      align: 'center',
                      width: '90',
                      sortable: false,
                    },
                  ]"
                  dense
                  :items-per-page="5"
                >
                  <template v-slot:item.Actions="{ item }">
                    <v-btn
                      @click="clickDeleteRecomendations(item)"
                      x-small
                      color="error"
                      elevation="0"
                      block
                    >
                      <v-icon style="font-size: 16px !important"
                        >fas fa-times</v-icon
                      >
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-0 pb-0"><h3>Notas</h3></v-col></v-row
            >
            <v-row><v-divider></v-divider></v-row>
            <v-row>
              <v-col>
                <s-textarea v-model="VstNoteRecomendation" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <h3>Actividades Culturales</h3>
              </v-col>
            </v-row>
            <v-row><v-divider></v-divider></v-row>
            <v-row>
              <v-col lg="2" class="pb-0 pt-0" v-for="i in itemsTypeActivities">
                <s-switch
                  :label="
                    i.DedDescription ? i.DedDescription : i.TypeActivityName
                  "
                  v-model="i.AcuStatus"
                  color="primary"
                ></s-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <h3>Observaciones</h3>
              </v-col>
            </v-row>
            <v-row><v-divider></v-divider></v-row>
            <v-row>
              <v-col>
                <s-textarea v-model="VstObservation" />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-spacer />
              <v-col lg="3" cols="12">
                <v-btn block color="warning" rounded @click="$emit('close')"
                  >Cancelar</v-btn
                >
              </v-col>
              <v-col lg="3" cols="12">
                <v-btn color="primary" rounded @click="save()" block
                  >Guardar</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>
<script>
import SSearchLotByCodSenasa from "@/components/HarvestSchedule/sSearchLotByCodSenasa.vue";
import _sDefinition from "@/services/General/DefinitionService.js";
import _sVisit from "@/services/Technicalassistance/Visit/Visit.js";
import SSelectPlagues from "@/components/TecnicalAssistence/Plagues/SSelectPlagues";
import sSelectListsupplies from "@/components/TecnicalAssistence/ListSupplies/SSelectListsupplies";
import visitPerformedApplication from "@/views/TechnicalAssistance/Visits/VisitPerformedApplication";
import instructionSubjectInstructor from "@/views/HumanResource/HumanManagement/IntructionDetail/Intructor";
export default {
  components: {
    SSelectPlagues,
    sSelectListsupplies,
    visitPerformedApplication,
    SSearchLotByCodSenasa,
    instructionSubjectInstructor,
  },
  props: {
    intructionSchedule: { type: Object, default: null },
  },
  data() {
    return {
      lotSenasa: null,
      NtpID: null,
      TasVisitPerformedApplications: [],
      DoceApplication: null,
      UnitMeasurement: null,
      objProductApplication: null,
      objProductRecomedation: null,
      itemsRecomendation: [],
      objPlagueRecomendation: null,
      RecDoce: "",
      itemsActivities: [],
      ActBeginDate: null,
      ActEndDate: null,
      objTypeActivity: null,
      DoceRecomendation: "",
      objProductRecomendation: null,
      objTypeApplication: null,
      VstDate: "",
      VstNoWaste: null,
      VstNoteRecomendation: "",
      VstObservation: "",
      itemsTypePhenological: [],
      objOrganPlant: null,
      PercentagePlague: "",
      objPlague: null,
      itemsPhenological: [],
      itemsTypeInstallations: [],
      itemsTypeActivities: [],
    };
  },
  created() {
    if (this.intructionSchedule.VstID != null) {
      //CARGAR VISITA
      _sVisit
        .find(this.intructionSchedule.VstID, this.$fun.getUserID())
        .then((r) => {
          this.itemsTypePhenological = r.data.TasVisitStagePhenologicals;
          this.itemsPhenological = r.data.TasVisitPhytosanitaryEvaluations;
          this.itemsActivities = r.data.TasVisitPerformedActivities;
          this.itemsTypeInstallations = r.data.TasVisitInstallationEvaluations;
          this.itemsRecomendation = r.data.TasVisitRecomendations;
          this.TasVisitPerformedApplications =
            r.data.TasVisitPerformedApplications;
          this.itemsTypeActivities = r.data.TasVisitActivityCulturals;
          this.VstDate = r.data.VstDate;

          this.VstNoteRecomendation = r.data.VstNoteRecomendation;
          this.VstObservation = r.data.VstObservation;

          console.log(r.data);
        });
    } else {
      //TIPO DE ETAPA FENOLOGICA; para completar los stext
      _sDefinition.definitiongroup(1207, 0, this.$fun.getUserID()).then((r) => {
        this.itemsTypePhenological = r.data;
      });
      //TIPO DE EVALUACIÓN DE INSTALACIONES
      _sDefinition.definitiongroup(1209, 0, this.$fun.getUserID()).then((r) => {
        this.itemsTypeInstallations = r.data;
      });
    }
  },
  methods: {
    inputProductRecomendation(product) {
      if (product) this.unitRecomendation = product;
    },
    searchSenasa(lot) {
      this.lotSenasa = lot;
    },
    orderRecomendations() {
      let i = 0;
      this.itemsRecomendation.map((e) => {
        i++;
        e.Line = i;
      });
    },
    clickDeleteRecomendations(item) {
      this.itemsRecomendation = this.itemsRecomendation.filter(
        (x) => x.Line != item.Line
      );
      this.orderRecomendations();
    },
    addRecomendation() {
      if (this.DoceRecomendation == "" || this.DoceRecomendation == "") {
        this.$refs.DoceRecomendation.error("Ingrese Dosis Recomendada");
        return;
      }
      this.itemsRecomendation.push({
        TypeApplication: this.objTypeApplication
          ? this.objTypeApplication.DedValue
          : null,
        TypeApplicationName: this.objTypeApplication
          ? this.objTypeApplication.DedDescription
          : null,
        Line: this.itemsRecomendation.length + 1,
        PlID: this.objPlagueRecomendation
          ? this.objPlagueRecomendation.PlID
          : null,
        PlName: this.objPlagueRecomendation
          ? this.objPlagueRecomendation.PlDescription
          : null,
        LsID: this.objProductRecomedation
          ? this.objProductRecomedation.LsID
          : null,
        LsName: this.objProductRecomedation
          ? this.objProductRecomedation.LsDescription
          : null,
        TypeUnit: this.objProductRecomedation
          ? this.objProductRecomedation.LsUnitID
          : null,
        TypeUnitName: this.unitRecomendation
          ? this.unitRecomendation.DedDescription
          : null,
        RcmDose: this.DoceRecomendation,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      });
    },
    save() {
      this.$fun.alert("Seguro de guardar?", "question").then((r) => {
        if (r.value) {
          if (
            this.intructionSchedule.VstID == 0 ||
            this.intructionSchedule.VstID == null
          ) {
            this.itemsTypePhenological.map((item) => {
              item.TypeStatePhenological = item.DedValue;
              item.SecStatus = 1;
              item.UsrCreateID = this.$fun.getUserID();
            });
            this.itemsTypeInstallations.map((item) => {
              item.TypeInstallation = item.DedValue;
              item.SecStatus = 1;
              item.UsrCreateID = this.$fun.getUserID();
            });
            this.itemsTypeActivities.map((item) => {
              item.TypeActivity = item.DedValue;
              item.SecStatus = 1;
              item.UsrCreateID = this.$fun.getUserID();
            });
          }

          if (this.TasVisitPerformedApplications.length <= 0) {
            this.$fun.alert(
              "Falta Registrar Aplicaciones Realizadas",
              "warning"
            );
            return;
          }

          let ObjVisit = {
            FltID: this.intructionSchedule.FltID
              ? this.intructionSchedule.FltID
              : this.lotSenasa.FltID,
            NtpID: this.intructionSchedule.NtpID
              ? this.intructionSchedule.NtpID
              : this.NtpID,
            UsrCreateID: this.$fun.getUserID,
            SecStatus: 1,
            VstStatus: 1,
            TasVisitActivityCulturals: this.itemsTypeActivities,
            TasVisitInstallationEvaluations: this.itemsTypeInstallations,
            TasVisitPerformedActivities: this.itemsActivities,
            TasVisitPerformedApplications: null,
            TasVisitPhytosanitaryEvaluations: this.itemsPhenological,
            TasVisitRecomendations: this.itemsRecomendation,
            TasVisitStagePhenologicals: this.itemsTypePhenological,
            TasVisitPerformedApplications: this.TasVisitPerformedApplications,
          };
          ObjVisit.TvID = this.intructionSchedule.TvID;
          ObjVisit.VstDate = this.VstDate;
          ObjVisit.VstID = this.intructionSchedule.VstID;
          ObjVisit.VstWasteFree = this.VstNoWaste;
          ObjVisit.VstNoteRecomendation = this.VstNoteRecomendation;
          ObjVisit.VstObservation = this.VstObservation;
          _sVisit.save(ObjVisit, this.$fun.getUserID()).then((r) => {
            this.$fun.alert("Guardado con Exito", "success");
            this.$emit("close");
          });
        }
      });
    },
    orderActivities() {
      let i = 0;
      this.itemsActivities.map((e) => {
        i++;
        e.Line = i;
      });
    },
    clickDeleteActivities(item) {
      this.itemsActivities = this.itemsActivities.filter(
        (x) => x.Line != item.Line
      );
      this.orderActivities();
    },
    addActivities() {
      if (!this.objTypeActivity) {
        this.$fun.alert("Seleccione Actividad", "warning");
        return;
      }
      this.itemsActivities.push({
        TypeActivity: this.objTypeActivity
          ? this.objTypeActivity.DedValue
          : null,
        Line: this.itemsActivities.length + 1,
        PacBeginDate: this.ActBeginDate,
        PacEndDate: this.ActEndDate,
        TypeActivityName: this.objTypeActivity
          ? this.objTypeActivity.DedDescription
          : null,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      });
    },
    orderPhenological() {
      let i = 0;
      this.itemsPhenological.map((e) => {
        i++;
        e.Line = i;
      });
    },
    clickDeletePhenological(item) {
      this.itemsPhenological = this.itemsPhenological.filter(
        (x) => x.Line != item.Line
      );
      this.orderPhenological();
    },
    addPhenological() {
      if (!this.objPlague) {
        this.$fun.alert("Complete plaga, maleza o enfermedad", "warning");
        this.$refs.objPlague.focus();
        return;
      }
      if (!(this.PercentagePlague > 0 && this.PercentagePlague < 100)) {
        this.$refs.PercentagePlague.error("Porcentaje Incorrecto");
        return;
      }
      this.$refs.PercentagePlague.setColor("primary");
      this.itemsPhenological.push({
        TypeOrganPlant: this.objOrganPlant ? this.objOrganPlant.DedValue : null,
        Line: this.itemsPhenological.length + 1,
        PlID: this.objPlague.PlID,
        PlName: this.objPlague.PlDescription,
        TypeOrganName: this.objOrganPlant
          ? this.objOrganPlant.DedDescription
          : null,
        PhePercentage: this.PercentagePlague,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      });
      this.PercentagePlague = null;
      this.objOrganPlant = null;
    },
    updateDataActivities(items) {
      this.itemsTypeActivities = items;
    },
  },
  watch: {
    objProductApplication() {
      this.UnitMeasurement = this.objProductApplication.LsUnitID;
    },
  },
};
</script>
